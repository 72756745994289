:root {
    --brand-primary            : #05141F;
    --brand-primary-lighten-10 : #0c304b;
    --brand-primary-lighten-40 : #2185cf;
    --brand-primary-darken-5   : #010609;
    --brand-primary-darken-10  : black;
    --brand-primary-darken-12  : black;
    --brand-primary-opacity-25 : #05141f40;
    --brand-primary-opacity-50 : #05141f80;

    --brand-secondary          : var(--brand-primary);

    --highlight                : #b6b6b6;
    --highlight-tint           : #e6e6e6;

    --navbar-height            : 60px;
    --navbar-brand-width       : 220px;
    --navbar-brand-height      : 60px;
    --navbar-brand-margin-left : 10px;
    --navbar-brand-logo-size   : auto;
    --navbar-brand-logo        : url('/assets/images/kia-us_logo.png');
    --navbar-brand-logo-mobile : url('/assets/images/kia-us_logo.png');

    --logo-gap                 : 24px;
    --logo-bg-size             : auto;
    --login-logo-height        : auto;
    --login-logo-width         : 171px;
    --login-logo               : url('/assets/images/powered_by.png');
    --login-brand-logo         : url('/assets/images/clients/kia-us/login_logo.png');
    --login-brand-logo-width   : 200px;
    --login-brand-logo-height  : 48px;

    --sidebar-width            : 220px;
    --mobile-sidebar-width     : 220px;

    --sidebar-bg               : #000;
    --sidebar-bg-darken-3      : black;
    --sidebar-bg-darken-5      : black;
    --sidebar-bg-darken-10     : black;
    --sidebar-bg-lighten-5     : #0d0d0d;

    --combined-bar-green       : #18b52d;
    --combined-bar-blue        : #224197;
    --combined-bar-yellow      : #f9bb11;
    --combined-bar-red         : #c51230;
}